.forgot-password {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9dfe0 !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.outer {
  padding-top: 10vh;
}

.forgot-password h1 {
  text-align: center;
  margin-bottom: 20px;
}

.forgot-password form {
  display: flex;
  flex-direction: column;
}

.forgot-password .mb-2 {
  margin-bottom: 15px;
}

.forgot-password input[type="email"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.forgot-password button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #324e7c;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.forgot-password button:hover {
  background-color: #4c76ba;
}

.forgot-password button:active {
  background-color: #24385a !important;
}
.logo-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.logo {
  width: 5vw; /* Adjust width as needed */
  margin-right: 10px; /* Adjust margin as needed */
  min-width: 30px;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.heading h1 {
  margin-top: 20px;
}

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
@font-face {
  font-family: "MaadicoFont";
  src: url("./fonts/BrolimoRegular-ALgg2.ttf") format("truetype");
  /* Add additional font formats if necessary */
  /* Specify the correct path to the font files */
}

body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to right, #e5efff, #e5efff);
  overflow-x: hidden; /* Hide horizontal overflow */
}

.bg-lightgray {
  background-color: #e5efff; /* Light gray background color */
  color: white;
}

/* Carousel */
.carousel-image {
  width: 100% !important;
  height: 75vh !important;
  max-height: 75vh !important; /* Ensures the images fit within the screen */
  object-fit: cover !important; /* Ensure images cover entire carousel item */
  object-position: center !important; /* Center the images */
}

.carousel-caption {
  background: rgba(
    0,
    0,
    0,
    0.6
  ); /* Semi-transparent black background for captions */
  color: white;
  text-align: center;
}

@media (min-aspect-ratio: 4/3) {
  .carousel-image {
    max-height: 100%;
    height: 100%;
    width: auto;
    max-width: 100vw;
    object-fit: cover;
  }
}

.gradient-custom {
  background: radial-gradient(50% 123.47% at 50% 50%, #00ff94 0%, #720059 100%),
    linear-gradient(121.28deg, #669600 0%, #ff0000 100%),
    linear-gradient(360deg, #0029ff 0%, #8fff00 100%),
    radial-gradient(100% 164.72% at 100% 100%, #6100ff 0%, #00ff57 100%),
    radial-gradient(100% 148.07% at 0% 0%, #fff500 0%, #51d500 100%);
  background-blend-mode: screen, color-dodge, overlay, difference, normal;
}
.carousel-indicators {
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

/* Homepage hero */

/* UserHomepageHero.css */
.user-homepage-hero {
  padding-left: 0;
}

.hero-content {
  padding: 5rem;
  text-align: center;
  background-image: url("https://images.news18.com/ibnlive/uploads/2022/04/yoga-164923092416x9.png");
  background-size: cover;
  background-position: center;
  height: 90vh;
}

.hero-content .hero-text {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  height: 100%;
}

.hero-content .hero-text h1 {
  margin-bottom: 1.5rem;
  color: #ffffff;
}

.hero-content .hero-text h4 {
  margin-bottom: 1.5rem;
  color: #ffffff;
}

.cta-buttons {
  margin-top: 1rem;
}

.cta-button {
  display: inline-block;
  padding: 1rem 2rem;
  margin-right: 1rem;
  text-decoration: none;
  color: #ffffff;
  text-shadow: none;

  background-color: rgba(0, 0, 0, 0.4);
  border: 2px solid #ffffff;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.cta-button:hover {
  background-color: #ffffff;
  color: #333333;
}

/* Get course */

/* courseComponent.css */

/* Center the heading */
.course-heading {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.course-view-page {
  height: 82vh;
}
/* Style for the card container */
.course-container {
  margin-top: 20px;
}

/* Style for each card */
.course-card {
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Style for card body */
.course-card-body {
  padding: 20px;
}

/* Style for card title */
.course-title {
  font-weight: bold;
  margin-bottom: 10px;
}

/* Style for card description */
.course-description {
  margin-bottom: 10px;
}

/* Style for card price */
.course-price {
  margin-bottom: 10px;
}

/* Style for register button */
.course-button {
  width: 100%;
}

.contact-hero {
  padding-left: 0;
}
.contact-content {
  border-top: #ffffff 2px solid;
  border-radius: 7% 7% 0 0;
  padding: 2rem;
  text-align: center;
  background-image: url("./assets/contactbg.jpg");
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 15vw;
}
@media screen and (max-width: 767px) {
  .contact-content {
    background-image: url("./assets/contactbgs.jpg");
  }
}

.cta-button.contact-us {
  padding: 1rem 1rem;
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.cta-button.contact-us:hover {
  background-color: #cccccc;
}

/* Contact us */
.contacts {
  height: auto;
  width: 100%;
  background-color: rgb(246, 252, 252);
}

/* .contactHeading .headingContact {
  color: black;
  font-size: 2rem;
  display: inline-block;
  font-weight: 800;
}

.contactHeading .contactIcon {
  color: black;
  font-size: 2.2rem;

}

.imagContainerContact {
  margin: 30px 0 !important;
  background-color: rgb(50, 78, 124);
  border-radius: 0 170px 0 0;
}

.imagContainerContact img {
  border-radius: 0 190px 0 0;
  height: auto;
  width: 90%;
  margin-top: 50px;
  left: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.colSetContact {
  margin: 30px 0;
  border-radius: 0 130px 0 0;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width:1199px) {
  .contactHeading .headingContact {
      color: black;
      font-size: 1.4rem;
      display: inline-block;
      font-weight: 800;
  }

  .contactHeading .contactIcon {
      color: black;
      font-size: 1.6rem;

  }
}

@media screen and (max-width:1028px) {
  .imagContainerContact {
      margin: 10px 0 !important;
      border-radius: 0 100px 0 0;
  }

  .imagContainerContact img {
      border-radius: 0 100px 0 0;

  }
}

@media screen and (max-width:991px) {
  .imagContainerContact {
      margin: 10px 0 !important;
      background-color: rgb(50, 78, 124);
      border-radius: 0 100px 0 0;
  }

  .imagContainerContact img {
      border-radius: 0 100px 0 0;
      height: 100%;
      width: 90%;

      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .atSmall991FullContact {
      width: 100% !important;
  }


}

@media screen and (max-width:767px) {
  .imagContainerContact {
      display: none;
  }
} */

.leftContactList {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f6fcfc;
  padding: 10px 0;
  margin: 10px 0;
  /* width: 300px; */
}

.leftContactList .l {
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #18325b;
}
.contactus-btn {
  background-color: #324e7c !important;
}
.r {
  width: 200px;
}

.l span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px !important;
}

.r p {
  line-height: 4px;
  opacity: 0.8;
}

.rightContactList {
  background-color: #f6fcfc;
  padding: 0px 10px;
}

@media screen and (max-width: 991px) {
  .r {
    width: 200px;
    font-size: small !important;
  }
}

@media screen and (max-width: 767px) {
  .leftContactList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }

  .removeMarginAt767 {
    margin: initial !important;
    padding: initial !important;
  }

  .rightContactList {
    background-color: #f6fcfc;
    padding: 0px 10px !important;
    margin: 0 auto !important;
    width: 99.99%;
  }

  .sizeDecCon p {
    font-size: small;
    text-align: justify;
  }
}

.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.privacy-container {
  line-height: 30px;

  margin-top: 30px;
  padding: 3rem;
}

.fixIcon {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 9999999999999;
  bottom: 10px;
  right: 15px;
}

.icon1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #18325b;
  margin: 15px 0;
  border: #545962 1px solid;
}

.maadico-font {
  font-family: "MaadicoFont" !important;
  font-weight: 130;
  color: #18325b;
}
.faq-container {
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 60px;
  font-size: 1.05rem;
  --bs-accordion-active-bg: #efefef !important;
  --bs-accordion-btn-focus-box-shadow: none !important;
}
.accordion-button {
  font-size: 1.1rem !important;
}
@media (min-width: 992px) {
  .faq-container {
    margin-left: 10%;
    margin-right: 10%;
  }
}

.container2 {
  /* display: flex; */
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 30px;
  /* background-color: #ffffff; */
  min-height: 100vh;
}

.descdoctor2 {
  margin-top: 30px;
  margin-left: 10px;
}

.gallery-section {
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 5%;
  margin-right: 5%;
}

.gallery-row {
  margin-top: 20px;
}

.image-container {
  padding: 10px;
  margin-bottom: 10px;
}

.image-container img {
  transition: transform 0.2s ease-in-out;
}

.image-container img:hover {
  transform: scale(1.05);
}

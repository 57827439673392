.register {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9dfe0 !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the form vertically */
}
.login-bg {
  background-image: url("../../assets/authlogin.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.register h1 {
  text-align: center;
  margin-bottom: 20px;
}

.register form {
  width: 100%;
}

.register .mb-3 {
  margin-bottom: 20px;
}

.register input[type="email"],
.register input[type="password"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.password-input {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.register button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #324e7c;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register button:hover {
  background-color: #4c76ba;
}

.register button:active {
  background-color: #24385a !important;
}

.outer {
  padding-top: 10vh;
}

.navigator:hover {
  cursor: pointer;
}

.logo-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.logo {
  width: 5vw; /* Adjust width as needed */
  margin-right: 10px; /* Adjust margin as needed */
  min-width: 30px;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.heading-text {
  margin-top: 20px;
}

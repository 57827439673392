.doctor {
  height: auto;
  background-color: rgb(246, 252, 252) !important;
  width: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex !important;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  transition: all 0.4s;
}

.swiper-slide:hover {
  transform: scale(0.95);
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.swiper {
  width: 100%;
  height: 500px;
  margin: 20px auto;
  align-items: center;
}

.details {
  position: absolute;
  bottom: 0px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
  border-radius: 8px;
  padding-top: 15px;
  width: 100%;
}

.swiper-slide:hover .details {
  transform: scale(1.01);
}

.name,
.department,
.social {
  margin: auto !important;
}

.name h6 {
  font-weight: 800;
  color: black;
}

/* .swiper-slide:hover .name h6 {
    color: white;
} */

.department p {
  color: grey;
  opacity: 0.8;
  line-height: 3px;
}

.swiper-slide:hover .department p {
  opacity: 1;
}

.socialId {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  display: none;
  line-height: 0px;
}

.socialId span {
  margin: 0 10px;
  color: black;
  line-height: 0px;
}

/* .swiper-slide:hover .socialId {
    display: flex;
} */

.swiper-button-next,
.swiper-button-prev {
  background-color: rgb(67, 204, 184);
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 1.5rem;
  color: white;
}

.aboutSubheading strong {
  color: #162d53;
}
@media screen and (max-width: 525px) {
  .swiper-slide img {
    /* display: block; */
    max-width: 300px;
    height: 300px;
    /* object-fit: cover; */
    border-radius: 8px;
  }

  .aboutSubheading {
    text-align: center;
  }

  .department p {
    font-size: 1rem !important;
  }

  .details {
    position: absolute;
    bottom: 10px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.4s;
    border-radius: 8px;
    max-width: 300px;
  }
}

.description {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: small;
  padding-left: 10px;
  padding-right: 10px;
}

.product {
  min-height: 100vh;
  /* background-color: rgb(246, 252, 252); */
}

.card {
  border-radius: 7px !important;
  border-color: #e1e7ec;
  box-shadow: inherit !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.card-img-tiles {
  display: block;
  border-bottom: 1px solid #e1e7ec;
}

.card-img-tiles {
  color: #0da9ef;
  text-decoration: none !important;
}

.card-img-tiles > .inner {
  display: table;
  width: 100%;
  /* height: 250px; */
}

.viewDetails {
  color: white;
  background-color: rgb(24, 50, 91);
}

.viewDetails:hover {
  color: white;
  background-color: rgb(24, 50, 91);
}

.card-img-tiles .main-img,
.card-img-tiles .thumblist {
  display: table-cell;
  width: 65%;
  padding: 15px;
  height: 100%;
  vertical-align: middle;
}

.card-img-tiles .main-img > img:last-child,
.card-img-tiles .thumblist > img:last-child {
  margin-bottom: 0;
}

.card-img-tiles .main-img > img,
.card-img-tiles .thumblist > img {
  display: block;
  width: 100%;

  /* object-fit: cover; */
  margin-bottom: 6px;
}

.thumblist {
  width: 35%;
  border-left: 1px solid #e1e7ec !important;
  display: table-cell;
  width: 65%;
  padding: 15px;
  vertical-align: middle;
}

.card-img-tiles .thumblist > img {
  display: block;
  width: 100%;
  margin-bottom: 6px;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.45rem 0.5rem !important;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

/* productDetails page */
.productsDetailsMarginWidth97 {
  width: 97%;
  margin: auto;
}

.productsDetailsImage {
  height: 430px;
  /* background-color: white; */
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.detailsLeft_arrow {
  background-color: rgb(67, 204, 184);
  position: absolute;
  left: 0;
}

.detailsRight_arrow {
  background-color: rgb(67, 204, 184);
  position: absolute;
  right: 0;
}

.productsDetailsImage img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
  object-fit: cover !important;
}

.productsDetailsImageChild {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.productsDetailsImageChildImages {
  width: 200px;
  /* height: 150px; */
  background-color: white;
  border-radius: 8px;
  margin: 5px;
  display: flex;
  justify-content: baseline;
}

.productsDetailsImageChildImages img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
  object-fit: contain !important;
}

.headingOfProduct p {
  color: rgb(148, 148, 148);
}

.headingOfProduct h1 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2px;
}

.productsDetailsMarginWidth97price p {
  text-decoration: underline;

  font-size: small;
  color: rgb(24, 50, 91);
}

.price {
  font-weight: 600;
  font-size: larger;
  color: #212529;
}

.discountParent {
  position: absolute;
  top: 25px;
  right: -130px;
}

.productsDetailsMarginWidth97quantity h6 {
  font-weight: 700;
}

.productsDetailsMarginWidth97quantity button {
  width: fit-content;
  background-color: rgb(24, 50, 91);
  color: white;
  border-radius: 20px;
  cursor: pointer;
}

.productsDetailsMarginWidth97quantity button:hover {
  background-color: rgb(24, 50, 91);
  color: white;
}

.productsDetailsMarginWidth97instruction h1 {
  font-size: 1.6rem;
  font-weight: 700;
}

.productsDetailsMarginWidth97instruction p {
  line-height: 10px;
}

.addToCartProductsDetails button {
  width: fit-content;
  background-color: rgb(24, 50, 91);
  color: white;
  border-radius: 20px;
  cursor: pointer;
}

.products_details_description h1 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 2px;
}

.products_details_description p {
  color: rgb(148, 148, 148);
}

/* comment section */
.customersReview {
  background-color: white !important;
  width: 100%;
}

.customersReview h1 {
  font-weight: 900;
  font-size: 2rem;
}

.comment-wrapper .panel-body {
  max-height: 650px;
  overflow: auto;
}

.comment-wrapper .media-list .media img {
  width: 64px;
  height: 64px;
  border: 2px solid #e5e7e8;
}

.comment-wrapper .media-list .media {
  border-bottom: 1px dashed #efefef;
  margin-bottom: 25px;
}

.commenterName {
  display: flex;
  flex-direction: column;
}

/* Shop header */

.shopCartPos {
  position: relative;
}

.shopCartRel {
  position: absolute;
  left: 20px;
  top: -9px;
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  color: white;
  border-radius: 7px;
}

/* cart */
.notAddedCart {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.notAddedCart img {
  height: 300px;
  width: 300px;
}

.notAddedCart h6 {
  font-weight: 800;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.4rem;
}

.img-sm {
  width: 80px;
  height: 80px;
}

.itemside .info {
  padding-left: 15px;
  padding-right: 7px;
}

.table-shopping-cart .price-wrap {
  line-height: 1.2;
}

.table-shopping-cart .price {
  font-weight: bold;
  margin-right: 5px;
  display: block;
}

.text-muted {
  color: #969696 !important;
}

a {
  text-decoration: none !important;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0px;
}

.itemside {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.dlist-align {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

[class*="dlist-"] {
  margin-bottom: 5px;
}

.coupon {
  border-radius: 1px;
}

.price {
  font-weight: 600;
  color: #212529;
}

.btn.btn-out {
  outline: 1px solid #fff;
  outline-offset: -5px;
}

.btn-main {
  border-radius: 2px;
  text-transform: capitalize;
  font-size: 15px;
  padding: 10px 19px;
  cursor: pointer;
  color: #fff;
  width: 100%;
}

.btn-light {
  color: #ffffff;
  background-color: #f44336;
  border-color: #f8f9fa;
  font-size: 12px;
}

.btn-light:hover {
  color: #ffffff;
  background-color: #f44336;
  border-color: #f44336;
}

.btn-apply {
  font-size: 11px;
}

.incDecController input {
  height: 19px;
}

.ordersPage {
  background-color: white;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #0da9ef !important;
}

/*ProductsDetailsCompTwo  */
.productDetailsParent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.productName {
  align-self: center;
}

.whyItsAwesome {
  width: 100%;
  height: 8vh;
  background-color: rgb(24, 50, 91);
}

/* WhyItsAwesomeComp */
.WhyItsAwesomeComp {
  background-color: white;
}

.WhyItsAwesomeCompParent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.WhyItsAwesomeCompChild {
  width: 350px;
  padding: 10px;
  background-color: rgb(246, 252, 252);
  margin: 10px 4px;
  border-radius: 8px;
}

.WhyItsAwesomeCompPic {
  text-align: center;
}

.WhyItsAwesomeCompPic img {
  object-fit: contain;
}

.WhyItsAwesomeCompText h6 {
  color: rgb(126, 126, 126);
  font-size: large;
  font-weight: 800;
}

.WhyItsAwesomeCompText p {
  font-weight: 100;
  opacity: 0.6;
}

@media screen and (max-width: 991px) {
  .WhyItsAwesomeCompChild {
    width: 270px;
    padding: 12px;
    background-color: rgb(246, 252, 252);
    margin: 10px 4px;
    border-radius: 8px;
  }

  .WhyItsAwesomeCompText p {
    font-weight: 100;
    opacity: 0.6;
    font-size: small;
  }

  .WhyItsAwesomeCompParent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    /* background-color: #e1e7ec; */
  }
}

@media screen and (max-width: 767px) {
  .WhyItsAwesomeCompChild {
    width: 300px;
    padding: 12px;
    background-color: rgb(246, 252, 252);
    margin: 10px 4px;
    border-radius: 8px;
  }

  .WhyItsAwesomeCompText p {
    font-weight: 100;
    opacity: 0.6;
    font-size: small;
  }

  .WhyItsAwesomeCompParent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .priceWithDiscount .prices {
    position: relative;
    right: 200px;
  }

  .productsDetailsMarginWidth97 {
    width: 90% !important;
  }

  .productCircle svg {
    height: 100px !important;
    width: 100px !important;
  }

  .productName h4 {
    font-size: medium !important;
  }
}

@media screen and (max-width: 603px) {
  .productCircle svg {
    height: 70px !important;
    width: 70px !important;
  }

  .productName h4 {
    font-size: small !important;
  }
}

@media screen and (max-width: 450px) {
  .productDetailsParent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .productDetailsParentChild {
    margin: 5px 0;
  }

  .productCircle svg {
    height: 70px !important;
    width: 70px !important;
  }

  .productName h4 {
    font-size: small !important;
  }
}

@media screen and (max-width: 425px) {
  .priceWithDiscount {
    position: relative;
    right: 100px;
  }

  .productsDetailsImage {
    height: 400px;
    /* background-color: white; */
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .detailsLeft_arrow {
    background-color: rgb(67, 204, 184);
    position: absolute;
    left: 0;
  }

  .detailsRight_arrow {
    background-color: rgb(67, 204, 184);
    position: absolute;
    right: 0;
  }

  .productsDetailsImage img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover !important;
  }

  .productsDetailsImageChild {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .productsDetailsImageChildImages {
    width: 200px;
    height: 100px;
    background-color: white;
    border-radius: 8px;
    margin: 5px;
    display: flex;
    justify-content: baseline;
  }

  .productsDetailsImageChildImages img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
    object-fit: cover !important;
  }

  .headingOfProduct p {
    color: rgb(148, 148, 148);
  }

  .headingOfProduct h1 {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 2px;
  }

  .productsDetailsMarginWidth97price p {
    text-decoration: underline;

    font-size: small;
    color: rgb(24, 50, 91);
  }

  .priceWithDiscount .prices {
    position: relative;
    right: 100px;
  }

  .priceWithDiscount .prices span:nth-child(1) {
    font-weight: 600;
    font-size: 1.2rem;
  }

  .priceWithDiscount .prices span:nth-child(2) {
    font-weight: 700;
    font-size: 2rem;
    position: absolute;
    top: -50px;
    left: 13px;
  }

  .priceWithDiscount .discount {
    position: relative;
  }

  .priceWithDiscount .discount span:nth-child(1) {
    font-weight: 800;
    font-size: 1rem;
    color: grey;
  }

  .priceWithDiscount .discount span:nth-child(2) {
    font-weight: 600;
    font-size: 1.5rem;
    position: absolute;
    top: -50px;
    left: 13px;
    color: grey;
    text-decoration: line-through;
  }

  .discountParent {
    position: absolute;
    top: 25px;
    right: -130px;
  }

  .productsDetailsMarginWidth97quantity h6 {
    font-weight: 700;
  }

  .productsDetailsMarginWidth97quantity button {
    width: fit-content;
    background-color: rgb(24, 50, 91);
    color: white;
    border-radius: 20px;
    cursor: pointer;
  }

  .productsDetailsMarginWidth97quantity button:hover {
    background-color: rgb(24, 50, 91);
    color: white;
  }

  .productsDetailsMarginWidth97instruction h1 {
    font-size: 1.6rem;
    font-weight: 700;
  }

  .productsDetailsMarginWidth97instruction p {
    line-height: 10px;
  }

  .addToCartProductsDetails button {
    width: fit-content;
    background-color: rgb(24, 50, 91);
    color: white;
    border-radius: 20px;
    cursor: pointer;
  }

  .products_details_description h1 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 2px;
  }

  .products_details_description p {
    color: rgb(148, 148, 148);
    font-size: small;
  }
}
